<template>
  <transition name="slide" mode="out-in">
    <div class="card" v-if="isOnFirstPage">
      <div class="card-header">
        <h1>Load Snapshot</h1>
        <p>Please upload a snapshot file for the dataset {{ datasetInstance.name }}, for the date {{ snapshotDate }}.
          This data will be loaded into your dataset.</p>
      </div>
      <div class="card-body">
        <form action="" class="form" @submit.prevent="">
          <div class="form-group">
            <label for="" class="form-label">Data Upload</label>
            <div class="form-control file-upload" @click="attemptUploadData">
              <transition name="pop" mode="out-in">
                <div class="file-preview" v-if="uploadedFile">
                  <img src="@/assets/icons/file.png" alt="File Icon" class="file-icon">
                  <p class="file-name">{{ uploadedFile.name }}</p>
                  <p class="file-size">{{ getFileSize(uploadedFile) }}</p>
                </div>
                <img v-else src="@/assets/icons/upload.png" alt="Upload Icon" class="upload-icon">
              </transition>
            </div>
            <p class="error" v-if="error">{{ error }}</p>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <button class="button primary" @click="isOnFirstPage = false">Continue</button>
      </div>
    </div>
    <div class="card" style="max-width: 800px" v-else>
      <div class="card-header">
        <h1>Select Columns</h1>
        <p>Please map each column in the uploaded file to a column in the dataset.</p>
      </div>
      <div class="card-body">
        <form action="" class="form" @submit.prevent="">
          <div class="form-row" v-for="(col, idx) of uploadedColumns" :key="idx">
            <input type="text" class="form-control" :value="col.name">
            <span>→</span>
            <select class="form-control" v-model="col.mappedTo">
              <option value=""></option>
              <option :disabled="isColumnInUse(column.id)" :value="column.id" v-for="column of datasetInstance.columns" :key="column.id">{{ column.name }}</option>
            </select>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <button class="button primary" @click="attemptCreateSnapshot">Upload</button>
      </div>
    </div>
  </transition>
</template>

<script>
import * as snapshotApi from '../api/snapshot'

const MAX_FILE_SIZE = 5 * 1024 * 1024;

export default {
  name: 'CreateDatasetModal',
  props: {
    datasetInstance: Object,
    selectedDate: Date
  },
  data() {
    return {
      snapshotDate: this.formatDate(this.selectedDate),
      uploadedFile: null,
      uploadedData: [],
      uploadedColumns: [],
      isOnFirstPage: true,
      error: '',
    }
  },
  methods: {
    formatDate(dateObj) {
      console.log(dateObj.toISOString());
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`
    },
    getFileSize(file) {
      let bytes = file.size;
      const units = ['B', 'KB', 'MB', 'GB'];
      let unitIndex = 0;

      while (bytes >= 1024 && unitIndex < units.length - 1) {
        bytes /= 1024;
        unitIndex++;
      }

      return `${bytes.toFixed(1)} ${units[unitIndex]}`;
    },
    attemptUploadData() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'text/*'

      fileInput.onchange = (event) => {
        const file = event.target.files[0];

        this.error = '';
        if (file.size > MAX_FILE_SIZE) {
          this.error = 'File size is too large. Please upload a file under 5MB'
          return;
        }

        this.uploadedFile = file;
        if (this.uploadedFile) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const fileContent = e.target.result;
            this.parseCsvFile(fileContent);
          };

          reader.readAsText(this.uploadedFile);

        } else {
          console.debug('No file selected');
        }
      };

      fileInput.click();
    },
    parseCsvFile(text) {
      // Get rows
      let rows = text.split('\n');
      rows = rows.map(row => row.split(','));
      this.uploadedData = rows;

      // Get columns
      this.uploadedColumns = rows[0].map(column => {
        return {
          name: column,
          mappedTo: ''
        }
      })
    },
    isColumnInUse(id) {
      return this.uploadedColumns.some(col => col.mappedTo == id);
    },
    async attemptCreateSnapshot() {
      try {
        // Create a snapshot
        const snapshotDate = this.formatDate(this.selectedDate);
        const snapshot = await snapshotApi.create(this.datasetInstance.id, snapshotDate);
        console.info('Created new snapshot', snapshot);

        // Create a json object
        const result = this.uploadedData.map(row => {
          const resultRow = {};

          for (let column of this.datasetInstance.columns) {
            const idx = this.uploadedColumns.findIndex(col => col.mappedTo == column.id);
            if (idx > -1) {
              resultRow[column.name] = row[idx];
            } else {
              resultRow[column.name] = '';
            }
          }

          return resultRow;
        })

        const jsonString = JSON.stringify(result, null, 2);
        const jsonFile = new Blob([jsonString], { type: "application/json" });

        // Attempt to upload a file
        await snapshotApi.upload(snapshot.id, jsonFile);
        console.info('Uploaded file');

        // Close the modal
        this.$emit('closeModal');

      } catch (err) {
        console.error(err)
      }
    }
  },
  emits: ['openModal', 'closeModal']
}
</script>

<style scoped>
.card {
  max-width: 600px;
}

h1 {
  margin-bottom: 5px;
}

.file-upload {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-icon {
  width: 32px;
  height: 32px;
  display: block;
  pointer-events: none;
}

.file-preview {
  display: flex;
  gap: 15px;
  padding: 20px;
  background: #FFF;
  border-radius: 10px;
}

.file-icon {
  height: 32px;
  width: 32px;
  display: block;
}

.file-name {
  word-break: break-all;
}

.form-row {
  display: flex;
  align-items: center;
  gap: 15px;
}
</style>