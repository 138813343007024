<template>
  <div class="full">
    <nav-bar />
    <div class="split">
      <pages-sidebar />
      <div class="wrapper">
        <div class="wrapper-header">
          <h3>Datasets</h3>
          <button class="button small" @click="openModal('toggle-datasets')">Manage</button>
        </div>
        <transition name="fade">
          <div class="dataset-instance-list" v-if="loaded">
            <dataset-instance-card v-for="datasetInstance of store.datasetInstances" :key="datasetInstance.id"
              :datasetInstance="datasetInstance" @openModal="openModal" />
          </div>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="pop" mode="out-in">
            <toggle-datasets-modal v-if="modal.id == 'toggle-datasets'" @openModal="openModal"
              @closeModal="closeModal" />
            <create-dataset-modal v-else-if="modal.id == 'create-dataset'" @openModal="openModal"
              @closeModal="closeModal" />
            <edit-schema-modal v-else-if="modal.id == 'edit-schema'" :datasetProps="modal.data.datasetProps"
              @closeModal="closeModal" />
            <dataset-detail-modal v-else-if="modal.id == 'dataset-detail'" :datasetInstance="modal.data.datasetInstance"
              @openModal="openModal" @closeModal="closeModal" />
            <load-snapshot-modal v-else-if="modal.id == 'load-snapshot'" :datasetInstance="modal.data.datasetInstance"
              :selectedDate="modal.data.selectedDate" @closeModal="closeModal" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { store } from '../store'
import * as datasetApi from '../api/dataset.js'
import NavBar from '../components/NavBar.vue'
import PagesSidebar from '../components/PagesSidebar.vue'
import CreateDatasetModal from '../modals/CreateDatasetModal.vue'
import ToggleDatasetsModal from '../modals/ToggleDatasetsModal.vue'
import DatasetDetailModal from '../modals/DatasetDetailModal.vue'
import EditSchemaModal from '../modals/EditSchemaModal.vue'
import DatasetInstanceCard from '../components/datasets/DatasetInstanceCard.vue'
import LoadSnapshotModal from '../modals/LoadSnapshotModal.vue'

export default {
  name: 'SoftwareView',
  data() {
    return {
      store,
      datasets: [],
      modal: {
        open: false,
        id: '',
        data: {},
        prev: null
      },
      loaded: false
    }
  },
  created() {
    Promise.all([
      this.fetchDatasetInstances(),
      this.fetchDatasets()
    ]).then(() => {
      this.loaded = true;
    })
  },
  methods: {
    openModal(id, data, savePrev = false) {

      if (savePrev) {
        this.modal.prev = { ...this.modal };
      }

      this.modal.open = true;
      this.$nextTick(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      if (this.modal.prev) {
        this.modal = this.modal.prev;
        return;
      }

      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      }, 0)
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal(true);
      }
    },
    async fetchDatasets() {
      try {
        const datasets = await datasetApi.list();
        store.datasets = datasets;
        console.info(`Loaded ${datasets.length} datasets`);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchDatasetInstances() {
      try {
        const instances = await datasetApi.listEnabled();
        store.datasetInstances = instances;
        console.info(`Loaded ${instances.length} enabled datasets`);
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: { PagesSidebar, NavBar, ToggleDatasetsModal, CreateDatasetModal, DatasetDetailModal, EditSchemaModal, DatasetInstanceCard, LoadSnapshotModal }
}
</script>

<style scoped>
.full {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--color-dark);
}

.split {
  width: 100%;
  height: 1px;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.wrapper {
  flex: 1;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  background: var(--color-light);
  border-top-left-radius: 10px;
  position: relative;
}

.wrapper-header {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70px;
  background: #FFF;
  padding: 0px 20px;
  border-bottom: 1px solid #EEE;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wrapper-header h3 {
  font-size: 20px;
}

.dataset-instance-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  max-height: 100%;
  overflow-y: auto;
  padding-top: 64px;
  justify-content: flex-start;
}
</style>