import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import DatasetsView from '../views/DatasetsView.vue';
import AuthCallbackView from '../views/AuthCallbackView.vue';

const routes = [
  {
    path: '/',
    redirect: '/datasets'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/datasets',
    name: 'Datasets',
    component: DatasetsView
  },
  {
    path: '/auth/callback',
    name: 'Auth Callback',
    component: AuthCallbackView
  }
]

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
  linkActiveClass: 'is-active'
})

export default router;