<template>
  <p>Authorized. You may close this page.</p>
</template>

<script>
export default {
  name: 'AuthCallBackView'
}
</script>

<style scoped>

</style>