<template>
  <div class="card">
    <div class="card-header">
      <h1>Edit Schema</h1>
      <p>Please outline the columns that this dataset will contain. Tagging the columns to help with analyzing later.
      </p>
    </div>
    <div class="card-body">
      <table>
        <thead>
          <tr>
            <th>Column Name</th>
            <th>Column Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="column of columns" :key="column.id" ref="columns">
            <td><input type="text" class="form-control" v-model="column.name" @blur="onBlurInput"></td>
            <td>
              <select type="text" class="form-control" v-model="column.type">
                <option value="String">String</option>
                <option value="Number">Number</option>
                <option value="Date">Date</option>
                <option value="Boolean">Boolean</option>
                <option value="Timestamp">Timestamp</option>
                <option value="Hash">Hash</option>
                <option value="StringList">String List</option>
              </select>
            </td>
          </tr>
          <tr>
            <td><input type="text" class="form-control" placeholder="Add column" @keydown="appendNewRow"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <button class="button primary" @click="attemptCreateDataset">Create</button>
    </div>
  </div>
</template>

<script>
import * as datasetApi from '../api/dataset.js'
import { store } from '../store/index.js'

export default {
  name: 'CreateDatasetModal',
  props: {
    datasetProps: Object
  },
  data() {
    return {
      store,
      columns: []
    }
  },
  methods: {
    async attemptCreateDataset() {
      if (this.pending) {
        return;
      }

      try {
        this.pending = true;
        this.error = '';

        const {name, description, iconBase64} = this.datasetProps;
        const columns = this.columns.map(c => {
          return {
            name: c.name,
            type: c.type,
          }
        })
        const dataset = await datasetApi.create(name, description, iconBase64, columns);
        store.datasets.push(dataset);
        this.$emit('closeModal');

      } catch (err) {
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    },
    appendNewRow(event) {

      if (!String.fromCharCode(event.keyCode).match(/[A-Za-z0-9]/g)) {
        event.preventDefault();
        return;
      }

      this.columns.push({
        id: Math.random(),
        name: '',
        type: 'String'
      })

      this.$nextTick(() => {
        const columns = this.$refs.columns;
        if (!columns) return;

        const lastColumn = columns[columns.length - 1];
        lastColumn.querySelector('input').focus();
      })
    },
    onBlurInput() {
      this.columns = this.columns.filter(c => {
        return c.name.length > 0;
      })
    }
  },
  emits: ['closeModal']
}
</script>

<style scoped>
.card {
  max-width: 800px;
}

h1 {
  margin-bottom: 5px;
}

canvas.form-control {
  width: 128px;
  cursor: pointer;
}

table {
  width: 100%;
  table-layout: fixed;
}

td,
th {
  text-align: left;
  font-size: 18px;
}
</style>