<template>
  <div class="pages-sidebar">
    <div class="nav-items">
      <router-link class="nav-item" to="/">
        <img class="nav-icon" src="@/assets/icons/home.png" alt="Home Page">
        <span class="nav-label">Home</span>
      </router-link>
      <router-link class="nav-item" to="/datasets">
        <img class="nav-icon" src="@/assets/icons/datasets.png" alt="Datasets Page">
        <span class="nav-label">Datasets</span>
      </router-link>
      <router-link class="nav-item" to="/reports">
        <img class="nav-icon" src="@/assets/icons/dashboards.png" alt="Dashboards Page">
        <span class="nav-label">Dashboards</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesSidebar'
}
</script>

<style scoped>
.pages-sidebar {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-dark);
  padding: 20px;
}

.nav-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-item {
  padding: 15px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  opacity: 0.5;
}

.nav-item.is-active {
  background: #101116;
  opacity: 1;
}

.nav-label {
  color: #FFF;
}

.nav-icon {
  height: 20px;
  filter: invert(1);
  display: block;
}

@media screen and (max-width: 1000px) {
  
  .pages-sidebar {
    width: unset;
    padding: 10px;
  }

  .nav-label {
    display: none;
  }
}

</style>