<template>
  <div class="dataset-instance-card">
    <div class="dataset-icon" :style="{ backgroundImage: `url(${datasetInstance.iconBase64})` }"></div>
    <div class="dataset-info">
      <h2>{{ datasetInstance.name }}</h2>
      <span>{{ datasetInstance.description }}</span>
    </div>
    <div class="actions">
      <button class="button primary small" @click="$emit('openModal', 'dataset-detail', { datasetInstance })">View
        Dates</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatasetInstanceCard',
  props: {
    datasetInstance: Object
  },
  emits: ['openModal']
}
</script>

<style scoped>
.dataset-instance-card {
  width: calc((100% - 60px) / 4);
  border-radius: 10px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #FFF;
}

.dataset-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media screen and (max-width: 1800px) {
  .dataset-instance-card {
    width: calc((100% - 40px) / 3);
  }
}

@media screen and (max-width: 1400px) {
  .dataset-instance-card {
    width: calc(calc(100% - 20px) / 2);
  }
}

@media screen and (max-width: 1000px) {
  .dataset-instance-card {
    width: calc(100%);
  }
}

.dataset-icon {
  width: 100%;
  height: 50px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}

.actions {
  display: flex;
  gap: 10px;
}
</style>