<template>
  <div class="card">
    <div class="card-header">
      <h1>{{ datasetInstance.name }}</h1>
      <p>Select a date on the calendar for which you would like to view or load {{ datasetInstance.name }} data.</p>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for="" class="form-label">Snapshot History</label>
        <DatePicker expanded borderless trim-weeks color="yellow" :attributes="getAttributes()" v-model="selectedDate"
          timezone="UTC" :rules="{ hours: 0, minutes: 0, seconds: 0, milliseconds: 0, }" />
      </div>
    </div>
    <div class="card-footer">
      <div class="button-list">
        <button v-if="getSelectedSnapshot()" class="button primary"
          @click="downloadSelectedFile()">Download <img src="@/assets/icons/download.png" class="button-icon"></button>
        <button class="button secondary"
          @click="$emit('openModal', 'load-snapshot', { datasetInstance, selectedDate }, true)">Upload
          Data</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as snapshotApi from '../api/snapshot'
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css'

export default {
  name: 'DatasetDetailModal',
  props: {
    datasetInstance: Object
  },
  data() {
    return {
      selectedDate: new Date(),
      snapshots: [],
    }
  },
  created() {
    this.attemptFetchSnapshots();
  },
  methods: {
    async attemptFetchSnapshots() {
      try {
        this.snapshots = await snapshotApi.list(this.datasetInstance.id);
        console.debug(`Loaded ${this.snapshots.length} snapshots`);
      } catch (err) {
        console.error(err);
      }
    },
    async downloadSelectedFile() {
      try {
        const selectedSnapshot = this.getSelectedSnapshot();
        const blob = await snapshotApi.download(selectedSnapshot.id);
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'
        link.click();

      } catch (err) {
        console.error(err);
      }
    },
    formatDate(dateObj) {
      const year = dateObj.getUTCFullYear();
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`
    },
    getAttributes() {
      return [
        {
          bar: { color: 'green' },
          dates: this.snapshots
            .filter(snapshot => snapshot.status == 'Completed')
            .map(snapshot => new Date(snapshot.snapshotDate))
        },
        {
          bar: { color: 'red' },
          dates: this.snapshots
            .filter(snapshot => snapshot.status == 'Failed')
            .map(snapshot => new Date(snapshot.snapshotDate))
        },
        {
          bar: { color: 'gray' },
          dates: this.snapshots
            .filter(snapshot => snapshot.status != 'Completed' && snapshot.status != 'Failed')
            .map(snapshot => new Date(snapshot.snapshotDate))
        }
      ]
    },
    getSelectedSnapshot() {
      if (!this.selectedDate) return;
      const snapshotDate = this.formatDate(this.selectedDate);
      return this.snapshots.find(snapshot => snapshot.snapshotDate == snapshotDate);
    }
  },
  components: { DatePicker },
  emits: ['openModal', 'closeModal']
}
</script>

<style scoped>
.card {
  max-width: 600px;
}

h1 {
  margin-bottom: 5px;
}
</style>