import { postJson } from "./common";

export async function create(name, description, iconBase64, columns) {
  const { res, json } = await postJson('/dataset/create', { name, description, iconBase64, columns });
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  if (!json.dataset) {
    throw Error('Malformed response from backend');
  }

  return json.dataset;
}

export async function select(datasetIds) {
  const { res, json } = await postJson('/dataset/select', { datasetIds });
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
}

export async function list() {
  const { res, json } = await postJson('/dataset/list');
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  if (!json.datasets) {
    throw Error('Malformed response from backend');
  }

  return json.datasets;
}

export async function listEnabled() {
  const { res, json } = await postJson('/dataset/list-enabled');
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  if (!json.datasetInstances) {
    throw Error('Malformed response from backend');
  }

  return json.datasetInstances;
}