<template>
  <nav class="navbar">
    <img src="@/assets/zapcharts-logo.png" alt="ZapCharts Logo" class="logo">
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>
.navbar {
  width: 100%;
  height: 50px;
  background: var(--color-dark);
  flex-shrink: 0;
  padding: 0px 20px;
  display: flex;
  align-items: center;
}

.logo {
  /* margin-left: 20px; */
  height: 25px;
  display: block;
}
</style>