<template>
  <div class="card">
    <div class="card-header">
      <h1>Manage Datasets</h1>
      <p>Select below which datasets (types of records) that you want to track and analyze.</p>
    </div>
    <div class="card-body">
      <div class="dataset-list">
        <div class="dataset-list-item" v-for="item of items" :key="item.id">
          <div class="dataset-icon" :style="{ backgroundImage: `url(${item.iconBase64})` }"></div>
          <div class="dataset-info">
            <h3>{{ item.name }}</h3>
            <span>{{ item.description }}</span>
          </div>
          <div class="action-list">
            <img src="@/assets/icons/edit.png" alt="Edit" class="edit-icon">
            <label class="switch">
              <input type="checkbox" v-model="item.isEnabled">
              <i class="slider"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="button-list">
        <button class="button primary" @click="attemptSave">Save Changes</button>
        <button class="button" @click="$emit('openModal', 'create-dataset', {}, true)">Create New Dataset</button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../store'
import * as datasetApi from '../api/dataset'

export default {
  name: 'ToggleDatasetsModal',
  data() {
    return {
      store,
      items: []
    }
  },
  created() {
    this.items = store.datasets.map(dataset => {
      const isEnabled = store.datasetInstances.some(datasetInstance => {
        return datasetInstance.datasetId == dataset.id
      });
      return {
        ...dataset,
        isEnabled: isEnabled
      }
    })
  },
  methods: {
    async attemptSave() {
      const selectedDatasetIds = this.items
        .filter(datasetInstance => datasetInstance.isEnabled)
        .map(datasetInstance => datasetInstance.id);

      await datasetApi.select(selectedDatasetIds);
      console.debug(`Successfully selected ${selectedDatasetIds.length} dataset instances`);

      store.datasetInstances = await datasetApi.listEnabled();
      console.debug(`Fetched ${store.datasetInstances.length} instances from backend`);

      this.$emit('closeModal');
    }
  },
  emits: ['openModal', 'closeModal']
}
</script>

<style scoped>
.card {
  max-width: 600px;
}

.card-footer {
  display: flex;
  gap: 15px;
}

h1 {
  margin-bottom: 5px;
}

.dataset-list {
  margin: -15px;
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow-y: auto;
  padding: 15px;
}

.dataset-list-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
  border-top: 1px solid #F5F5F5;
}

.dataset-list-item h3 {
  margin-bottom: 5px;
}

.dataset-icon {
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.dataset-info {
  flex: 1;
}

.action-list {
  display: flex;
  align-items: center;
  gap: 15px;
}

.edit-icon {
  height: 24px;
  display: block;
  cursor: pointer;
}
</style>