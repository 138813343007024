import { postJson, postForm, postJsonForBlob } from "./common";

export async function create(datasetInstanceId, snapshotDate) {
  const { res, json } = await postJson('/snapshot/create', { datasetInstanceId, snapshotDate });
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  if (!json.snapshot) {
    throw Error('Malformed response from backend');
  }

  return json.snapshot;
}

export async function list(datasetInstanceId) {
  const { res, json } = await postJson('/snapshot/list', { datasetInstanceId });
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  if (!json.snapshots) {
    throw Error('Malformed response from backend');
  }

  return json.snapshots;
}

export async function upload(snapshotId, file) {
  const formBody = new FormData();
  formBody.set('snapshotId', snapshotId);
  formBody.set('snapshotFile', file);

  const { res, json } = await postForm('/snapshot/upload', formBody);
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
}

export async function download(snapshotId) {
  const { res, blob } = await postJsonForBlob('/snapshot/download', { snapshotId });
  if (res.status != 200) {
    throw Error(res.statusText);
  }
  return blob;
}