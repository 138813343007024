export async function postJson(url, body) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  });

  if (res.status == 401) {
    window.location.href = '/login';
  }

  const json = await res.json();
  return { res, json };
}

export async function postForm(url, formData) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    body: formData,
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    window.location.href = '/login';
  }

  const json = await res.json();
  return { res, json };
}

export async function postJsonForBlob(url, body) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(body)
  });

  if (res.status == 401) {
    window.location.href = '/login';
  }

  const blob = await res.blob();
  return { res, blob };
}