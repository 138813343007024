<template>
  <div class="full">
    <div class="login-panel">
        <!-- Login card -->
        <div class="card login-card">
          <div class="card-header">
            <h1 class="title">Create Account</h1>
            <p>Welcome to IntraHook Insights! Please register an account to get started.</p>
          </div>
          <div class="card-body">
            <form action="" class="form" @submit.prevent="attemptRegister">
              <div class="form-group">
                <label for="email" class="form-label">Email Address</label>
                <input id="email" type="text" class="form-control" v-model="email">
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input id="password" type="password" class="form-control" v-model="password">
              </div>
              <div class="form-group">
                <label for="repassword" class="form-label">Repeat Password</label>
                <input id="repassword" type="password" class="form-control" v-model="repassword">
              </div>
              <input type="submit" value="" hidden>
            </form>
          </div>
          <div class="card-footer">
            <button class="button form-button primary" @click.prevent="attemptRegister">Register</button>
            <p class="error" v-if="error">{{ error }}</p>
          </div>
        </div>
    </div>
    <div class="info-panel"></div>
  </div>
</template>

<script>
import * as accountApi from '../api/account.js'

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      repassword: '',
      pending: false,
      error: ''
    }
  },
  methods: {
    async attemptRegister() {
      if (this.pending) {
        return;
      }

      if (this.password != this.repassword) {
        this.error = 'Passwords do not match!'
        return;
      }

      try {
        this.pending = true;
        this.error = '';
        await accountApi.register(this.email, this.password);
        this.$router.push('/');
      } catch (err) {
        this.error = err.message;
      } finally {
        this.pending = false;
      }
    }
  }
}
</script>

<style scoped>
.full {
  width: 100%;
  height: 100vh;
  display: flex;
}

.info-panel {
  flex: 3;
  background: var(--color-dark);
  height: 100%;
}

.login-panel {
  padding: 50px;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top right, #e6eaff 30%, var(--color-light) 60%);
}
</style>