<template>
  <div class="card">
    <div class="card-header">
      <h1>Create Dataset</h1>
      <p>Register a new dataset on the IntraHook platform.</p>
    </div>
    <div class="card-body">
      <form action="" class="form" @submit.prevent="attemptCreateDataset">
        <div class="form-group">
          <label for="" class="form-label">Icon</label>
          <canvas ref="canvas" class="form-control" width="128" height="128" @click="attemptSelectIcon"></canvas>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Dataset Name</label>
          <input type="text" class="form-control" v-model="name">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Description</label>
          <input type="text" class="form-control" v-model="description">
        </div>
      </form>
    </div>
    <div class="card-footer">
      <button class="button primary" @click="openNextModal">Continue</button>
    </div>
  </div>
</template>

<script>
import { store } from '../store/index.js'

export default {
  name: 'CreateDatasetModal',
  data() {
    return {
      store,
      name: '',
      description: '',
      authUrl: '',
      error: ''
    }
  },
  methods: {
    attemptSelectIcon() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*'

      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#FFF';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Calculate image dimensions for best fit
            const imageAspectRatio = img.width / img.height;
            let displayWidth, displayHeight;
            if (imageAspectRatio > 1) {
              displayWidth = canvas.width;
              displayHeight = canvas.height / imageAspectRatio;
            } else {
              displayWidth = canvas.width * imageAspectRatio;
              displayHeight = canvas.height;
            }

            // Render to canvas
            const x = (canvas.width - displayWidth) / 2;
            const y = (canvas.height - displayHeight) / 2;
            ctx.drawImage(img, x, y, displayWidth, displayHeight);
          };
          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      });

      input.click();
    },
    openNextModal() {
      const canvas = this.$refs.canvas;
      const base64 = canvas.toDataURL();
      const datasetProps = { name: this.name, description: this.description, iconBase64: base64};
      this.$emit('openModal', 'edit-schema', { datasetProps }, false);
    }
  },
  emits: ['openModal', 'closeModal']
}
</script>

<style scoped>
.card {
  max-width: 600px;
}

h1 {
  margin-bottom: 5px;
}

canvas.form-control {
  width: 64px;
  cursor: pointer;
}
</style>